import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { SET_LOCK_SCREEN } from 'store/settings/actions'
import { useTranslation } from 'react-i18next'
//import io from 'socket.io-client'

// Reactstrap
import { Modal } from 'react-bootstrap'

// Import menuDropdown
import LanguageDropdown from "../topbarDropdown/LanguageDropdown";

import { POST, GET } from 'helpers/api'
import { clearTemporaryStorage, formatMoney } from 'helpers/helpers'

function Header() {
	const { t } = useTranslation()
	const navigate = useNavigate();
	const dispatch = useDispatch()

	//const socketRef = useRef()

	const layoutSelector = useSelector(state => state.Layout)

	const role = localStorage.getItem('role')

	const [modalLogout, setModalLogout] = useState(false)
	const [balance, setBalance] = useState(0)

	function toggleFullscreen() {
		if (
			!document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
			!document.webkitFullscreenElement
		) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(
					Element.ALLOW_KEYBOARD_INPUT
				);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}

	function lockScreen() {
		dispatch(SET_LOCK_SCREEN())
	}

	async function logout() {
		if (process.env.NODE_ENV === 'development') {
			dispatch({ type: 'USER_LOGGED_OUT', payload: null })
			clearTemporaryStorage()
			navigate('/auth/login')
			window.location.reload()
			return
		}
		const response = await POST('/auth/logout')
		if (response) {
			clearTemporaryStorage()
			navigate('/auth/login')
			window.location.reload()
		}
	}

	async function getBalance() {
		const response = await GET('/services/web/api/pos-balance')
		setBalance(response)
	}

	// function socketConnection() {
	// 	console.log('a1')
	// 	const baseURL = "http://my.idokon.uz:9501/online-users";
	// 	socketRef.current = io.connect(baseURL, {
	// 		transports: ['websocket'],
	// 		query: {
	// 			"login": JSON.parse(localStorage.getItem('authUser')).username,
	// 			"Authorization": localStorage.getItem('access_token')
	// 		}
	// 	});

	// 	socketRef.current.on('connect', function () {
	// 		console.log('Connected', socketRef);
	// 		console.log('Connected', socketRef.connected);
	// 	});

	// 	socketRef.current.on('disconnect', function () {
	// 		console.log('Disconnected');
	// 	});


	// 	socketRef.current.on('online-users', (data) => {
	// 		console.log(data);
	// 	})
	// }

	useEffect(() => {
		//socketConnection()
		if (role !== 'ROLE_ORGANIZATION') {
			getBalance()
		}

		// return () => {
		// 	socketRef?.current?.disconnect()
		// }
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<header id="page-topbar" className={layoutSelector.showSidebar ? 'sidebar-left' : 'sidebar-active-left'}>
				<div className="navbar-header">
					<div></div>

					<div className="d-flex">

						<LanguageDropdown />

						<button type="button"
							onClick={() => toggleFullscreen()}
							className="btn header-item noti-icon waves-effect"
							data-toggle="fullscreen">
							<i className="uil-minus-path"></i>
						</button>
						<button type="button"
							onClick={() => lockScreen()}
							className="btn header-item noti-icon waves-effect"
							data-toggle="fullscreen">
							<i className="uil-lock"></i>
						</button>
						<div className="d-lg-inline-block ms-1 dropdown" onClick={() => setModalLogout(true)}>
							<button type="button" className="btn header-item noti-icon waves-effect">
								<div className="d-flex">
									<div className="me-2">
										<div className="d-flex justify-content-between">
											<span>{t('LOGIN')}: </span>
											<span>{JSON.parse(localStorage.getItem('authUser'))?.username?.replaceAll('!2929s', '')}</span>
										</div>
										<div>	{t('BALANCE')}: {formatMoney(balance, 0)}</div>
									</div>
									<div className="vertical-center">
										<i className="uil-signin"></i>
									</div>
								</div>
							</button>
						</div>

					</div>
				</div>
			</header>

			<Modal show={modalLogout} animation={false}
				centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setModalLogout(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('LOGOUT')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('ARE_YOU_SURE_YOU_WANT_LOGOUT')}
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-warning w-100 me-2" onClick={() => setModalLogout(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => logout()}>{t('CONFIRM')}</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Header
