const initialState = {
	'documentInProductList': [],
	'documentInCreateData': {},
	'transferCreateData': {},
	'inventoryCreateData': {},
	'transferPos': {},
	'documentInCreateProduct': {},
}

const transferData = function (state = initialState, action) {
	switch (action.type) {
		case "SET_DOCUMENT_IN_PRODUCT_LIST":
			return { ...state, 'documentInProductList': action.payload };
		case "SET_DOCUMENT_IN_CREATE_DATA":
			return { ...state, 'documentInCreateData': action.payload };
		case "SET_TRANSFER_CREATE_DATA":
			return { ...state, 'transferCreateData': action.payload };
		case "SET_INVENTORY_CREATE_DATA":
			return { ...state, 'inventoryCreateData': action.payload };
		case "SET_TRANSFER_POS":
			return { ...state, 'transferPos': action.payload };
		case "SET_DOCUMENT_IN_CREATE_PRODUCT":
			return { ...state, 'documentInCreateProduct': action.payload };
		default:
			return state;
	}
};

export default transferData