import React from "react";

//i18n
import SidebarContent from "./SidebarContent";


const Sidebar = () => {

	return (
		<SidebarContent />
	);
};

export default Sidebar