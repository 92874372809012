import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './BarcodeForm.css';
import categories from './categories.json'; 

const BarcodeForm = () => {
    const [barcode, setBarcode] = useState('');
    const [oldBarcode, setOldBarcode] = useState('');
    const [category, setCategory] = useState(2);
    const [name, setName] = useState('');
    const [count, setCount] = useState(100);
    const [uom, setUom] = useState(1);
    const [lang, setLang] = useState("uz_cyrl");
    const [sheetNumber, setSheetNumber] = useState(1);
    const [price, setPrice] = useState(800);
    const [whPrice, setWhPrice] = useState(900);
    const [retailPrice, setRetailPrice] = useState(1000);
    
    const barcodeInputRef = useRef(null);

    var tempBarcode = ""

    useEffect(() => {
        const handleKeydown = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault(); // Prevent the default action of the Enter key
                if (barcode) {
                    //get current tracking
                    tempBarcode = barcode.slice(oldBarcode.length)

                    //submit previous
                    if(oldBarcode.length > 0){
                        setOldBarcode(tempBarcode);
                        handleSubmitByScanner(oldBarcode, tempBarcode)
                    }else{
                        setOldBarcode(tempBarcode);
                        setBarcode(tempBarcode)
                        fetchProductDetails(tempBarcode);
                    }

                    
                    console.log(tempBarcode)
                    console.log("old -> " + oldBarcode)
                    
                    
                }
            } else {
                setBarcode((prevBarcode) => prevBarcode);
            }
        };

        // Add event listener for keydown
        document.addEventListener('keydown', handleKeydown);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('keydown', handleKeydown);
        };
    }, [barcode]);


    const getKeyByValue = (object, value) => {
        return Object.keys(object).find(key => object[key] === value);
    };

    const fetchProductDetails = async (barcode) => {
        try {
            const response = await axios.get(`https://admin.sdpos.io/services/admin/api/productBase/findByBarCode?barCode=${barcode}`);
            const product = response.data;

            if(response.data){
                if(categories[product.productCategory]){
                    setCategory(categories[product.productCategory]);
                }else{
                    setCategory(2)
                }
                setName(product.name);
                setCount(product.count);
                setUom(product.uomId);
                setPrice(product.price);
                setWhPrice(product.wholesalePrice);
                setRetailPrice(product.salePrice);
            }else{
                var responseTasnif = await fetch('https://tasnif.soliq.uz/api/cls-api/elasticsearch/search?lang='+ lang + '&search=' + barcode);
			    var tasnif = await responseTasnif.json();
                if(tasnif.data.length > 0 && barcode == tasnif.data[0].internationalCode){
                    setName(tasnif.data[0].name)
                }else{
                    document.getElementById('error-sound').play();
                    setName("");
                }
                setCategory(2)
                setCount(100);
                setUom(1);
                setPrice(800);
                setWhPrice(900);
                setRetailPrice(1000);
            }
        } catch (error) {
            document.getElementById('error-sound').play();
            console.error('Error fetching product details:', error);
        }
    };

    const handleBarcodeChange = (e) => {
        setBarcode(e.target.value);
    };

    const handleBarcodeFocus = () => {
        if (barcodeInputRef.current) {
            barcodeInputRef.current.select();
        }
    };


    const handleSubmit = async () => {
        const data = { 
            "barCode": barcode, 
            "productCategory": getKeyByValue(categories, category), 
            "name": name, 
            "count": count, 
            "uomId": uom, 
            "price": price, 
            "wholesalePrice": whPrice, 
            "salePrice": retailPrice 
        };
        try {
            await axios.post(`https://admin.sdpos.io/services/admin/api/googleSheets/sendToSpreadSheets?sheetNumber=${sheetNumber}`, data);
            //await axios.post(`http://localhost:8080/api/sendToSpreadSheets?sheetNumber=${sheetNumber}`, data)
        } catch (error) {
            console.error('Error submitting data:', error);
        }
        setBarcode("")
        setName("");
        setCategory(2)
        setCount(100);
        setUom(1);
        setPrice(800);
        setWhPrice(900);
        setRetailPrice(1000);
        setOldBarcode('')
        
    };

    const handleSubmitByScanner = async (oldBC, newValue) => {
        const data = { 
            "barCode": oldBC, 
            "productCategory": getKeyByValue(categories, category), 
            "name": name, 
            "count": count, 
            "uomId": uom, 
            "price": price, 
            "wholesalePrice": whPrice, 
            "salePrice": retailPrice 
        };
        try {
            await axios.post(`https://admin.sdpos.io/services/admin/api/googleSheets/sendToSpreadSheets?sheetNumber=${sheetNumber}`, data);
            //await axios.post(`http://localhost:8080/api/sendToSpreadSheets?sheetNumber=${sheetNumber}`, data)
        } catch (error) {
            console.error('Error submitting data:', error);
        }
        setBarcode(newValue)
        fetchProductDetails(newValue)
        
    };

    return (
        <div className="d-flex justify-content-center">
            <audio id="error-sound" src={require('../../../assets/media/error.mp3')} preload="auto"></audio>
            <form>
                <div style={{marginBottom: '1rem'}}>
                    <select
                        id="lang"
                        value={lang}
                        onChange={(e) => setLang(e.target.value)}
                        required
                    >
                        <option value="ru">ruscha</option>
                        <option value="uz_latn">lotin</option>
                        <option value="uz_cyrl">kirill</option>
                    </select>
                </div>
                <div style={{marginBottom: '1rem'}}>
                    {/* <label htmlFor="barcode">Shrix kod:</label> */}
                    <input
                        ref={barcodeInputRef}
                        type="text"
                        id="barcode"
                        value={barcode}
                        onChange={handleBarcodeChange}
                        onFocus={handleBarcodeFocus}
                        required
                        style={{width: '80%', marginRight: '10px'}}
                    />
                    <button type="button" onClick={handleSubmit}>Add</button>
                </div>
                <div style={{marginBottom: '1rem'}}>
                    <label htmlFor="category">Kategoriya:</label>
                    <select
                        id="category"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        required
                    >
                        {Object.keys(categories).map((key) => (
                            <option value={categories[key]}>{key}</option>
                        ))}
                    </select>
                </div>
                <div style={{marginBottom: '1rem'}}>
                    <label htmlFor="name">Mahsulot nomi:</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div style={{marginBottom: '1rem'}}>
                    <label htmlFor="count">Soni:</label>
                    <input
                        type="text"
                        id="count"
                        value={count}
                        onChange={(e) => setCount(e.target.value)}
                        required
                    />
                </div>
                <div style={{marginBottom: '1rem'}}>
                    <label htmlFor="uom">O'lchov birligi:</label>
                    <select
                        id="uom"
                        value={uom}
                        onChange={(e) => setUom(e.target.value)}
                        required
                    >
                        <option value="1">dona</option>
                        <option value="2">kg</option>
                        <option value="3">litr</option>
                        <option value="4">m</option>
                        <option value="5">gr</option>
                        <option value="6">blok</option>
                        <option value="7">qadoq</option>
                        <option value="8">ml</option>
                        {/* Add more UOMs as needed */}
                    </select>
                </div>
                <div style={{marginBottom: '1rem'}}>
                    <label htmlFor="price">Kirish Narxi:</label>
                    <input
                        type="text"
                        id="price"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        required
                    />
                </div>
                <div style={{marginBottom: '1rem'}}>
                    <label htmlFor="whPrice">Optom narxi:</label>
                    <input
                        type="text"
                        id="whPrice"
                        value={whPrice}
                        onChange={(e) => setWhPrice(e.target.value)}
                        required
                    />
                </div>
                <div style={{marginBottom: '1rem'}}>
                    <label htmlFor="retailPrice">Sotish narxi:</label>
                    <input
                        type="text"
                        id="retailPrice"
                        value={retailPrice}
                        onChange={(e) => setRetailPrice(e.target.value)}
                        required
                    />
                </div>
                <div style={{marginBottom: '1rem'}}>
                    <label htmlFor="sheetNumber">List raqami:</label>
                    <select
                        id="sheetNumber"
                        value={sheetNumber}
                        onChange={(e) => setSheetNumber(e.target.value)}
                        required
                    >
                        <option value="1">List 1</option>
                        <option value="2">List 2</option>
                        <option value="3">List 3</option>
                        <option value="4">List 4</option>
                        <option value="5">List 5</option>
                        <option value="6">List 6</option>
                        <option value="7">List 7</option>
                        <option value="8">List 8</option>
                        <option value="9">List 9</option>
                        <option value="10">List 10</option>
                    </select>
                </div>
                
            </form>
        </div>
    );
};

export default BarcodeForm;
