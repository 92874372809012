import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'

import useDidMountEffect from 'pages/parts/useDidMountEffect'
import FilterSearch from 'pages/parts/FilterSearch'

import { GET } from 'helpers/api'
import { formatMoney, returnSign, formatDate, formatDateBackend, findFromArrayById } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const date = new Date()

	const [percentage, setPercentage] = useState(0)
	const [employeeExpences, setEmployeeExpences] = useState(0)
	const [totalEmployeeExpence, setTotalEmployeeExpence] = useState(0)
	const [poses, setPoses] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		startDate: formatDateBackend(new Date(date.getFullYear(), date.getMonth(), 1)),
		endDate: formatDateBackend(new Date()),
		posId: reduxAccount.posId,
		search: '',
	})
	const [data, setData] = useState({
		'cashierAmount': [],
		'inAmount': [],
		'salesAmount': [],
		'outAmount': [],
		'summaryPercent': {},
	})

	async function searchWithFilter(exportToExcel = false, tableSort = "") {
		var filterUrl = `/services/web/api/report-kpi/${filterData.posId}`
		var urlParams = ""
		checkFilter()

		if (filterData.organizationId)
			urlParams += returnSign(urlParams) + 'organizationId=' + filterData.organizationId
		if (filterData.productCategoryId)
			urlParams += returnSign(urlParams) + 'productCategoryId=' + filterData.productCategoryId
		if (filterData.startDate)
			urlParams += returnSign(urlParams) + 'startDate=' + filterData.startDate
		if (filterData.endDate)
			urlParams += returnSign(urlParams) + 'endDate=' + filterData.endDate
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if (`/services/web/api/report-kpi/${filterData.posId}` + urlParams !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		if (tableSort) {
			var filterDataCopy = { ...filterData }
			if (filterDataCopy.tableSortKey === tableSort) {
				filterDataCopy.tableSortDirection === ',asc' ? filterDataCopy.tableSortDirection = ',desc' : filterDataCopy.tableSortDirection = ',asc'
			} else {
				filterDataCopy.tableSortDirection = ',asc'
			}
			filterDataCopy.tableSortKey = tableSort

			setFilterData(filterDataCopy)
			urlParams += returnSign(urlParams) + 'sort=' + tableSort + filterDataCopy.tableSortDirection
		}

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (response) {
			setData(response)
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			startDate: formatDateBackend(new Date(date.getFullYear(), date.getMonth(), 1)),
			endDate: formatDateBackend(new Date()),
			posId: filterData.posId,
			search: '',
		})
		setFilterDataExist(false)
	}

	function checkFilter() {
		if (
			filterData.startDate === null &&
			filterData.endDate === null &&
			filterData.posId === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
		}
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('SUMMARY_REPORT')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('POS')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('PERIOD')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.startDate ? new Date(filterData.startDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'startDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.endDate ? new Date(filterData.endDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'endDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('APPLY')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{(filterData.startDate || filterData.endDate) &&
									<span className="me-2">
										<b>{t('PERIOD')}: </b>
										{filterData.startDate &&
											<span>{formatDate(filterData.startDate)}</span>
										}
										{(filterData.startDate && filterData.endDate) &&
											<span>|</span>
										}
										{filterData.endDate &&
											<span>{formatDate(filterData.endDate)}</span>
										}
									</span>
								}
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-3 mb-3">
					<div className="card">
						<div className="card-body">
							<h5 className="mb-1 text-center">{t('GOOD_RECEPTION')}</h5>
							<div className="table-responsive">
								<table className="table table-striped mb-0">
									<thead>
										<tr>
											<th className="text-center">{t('QUANTITY')}</th>
											<th className="text-center">{t('AMOUNT')}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className="text-center">{data?.inAmount?.quantity}</td>
											<td className="text-center">{formatMoney(data?.inAmount?.totalAmount)}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-3 mb-3">
					<div className="card">
						<div className="card-body">
							<h5 className="mb-1 text-center">{t('RETURN_OF_GOODS')}</h5>
							<div className="table-responsive">
								<table className="table table-striped mb-0">
									<thead>
										<tr>
											<th className="text-center">{t('QUANTITY')}</th>
											<th className="text-center">{t('AMOUNT')}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className="text-center">{formatMoney(data?.outAmount?.quantity)}</td>
											<td className="text-center">{formatMoney(data?.outAmount?.totalAmount)}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-3 mb-3">
					<div className="card">
						<div className="card-body">
							<div className="table-responsive">
								<h5 className="mb-1 text-center">{t('SALES')}</h5>
								<table className="table table-striped mb-0">
									<thead>
										<tr>
											<th className="text-center">{t('CHEQUES')}</th>
											<th className="text-center">{t('PRODUCTS')}</th>
											<th className="text-center">{t('SALE_AMOUNT')}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className="text-center">{data?.salesAmount?.countCheque}</td>
											<td className="text-center">{formatMoney(data?.salesAmount?.countProduct)}</td>
											<td className="text-center">{formatMoney(data?.salesAmount?.totalAmount)}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-3 mb-3">
					<div className="card">
						<div className="card-body">
							<div className="table-responsive">
								<h5 className="mb-1 text-center">{t('RETURN')}</h5>
								<table className="table table-striped mb-0">
									<thead>
										<tr>
											<th className="text-center">{t('CHEQUES')}</th>
											<th className="text-center">{t('PRODUCTS')}</th>
											<th className="text-center">{t('REFUND_AMOUNT')}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className="text-center">{data?.salesAmount?.countReturnedCheque}</td>
											<td className="text-center">{formatMoney(data?.salesAmount?.countReturnedProduct)}</td>
											<td className="text-center">{formatMoney(data?.salesAmount?.totalReturnedAmount)}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 mb-3">
					<div className="card">
						<div className="card-body">
							<h5 className="mb-1 text-center">{t('SUMMARY_REPORT')}</h5>
							<div className="table-responsive">
								<table className="table table-striped mb-0">
									<thead>
										<tr>
											<th>{t('DOCUMENT_IN')}</th>
											<th className="text-center">{t('SALES')}</th>
											<th className="text-center">{t('REFUND_AMOUNT')}</th>
											<th className="text-center">{t('GROSS_INCOME')}</th>
											<th className="text-center">Uget</th>
											<th className="text-center">{t('CONSUMPTION')}</th>
											<th className="text-center">{t('EMPLOYEES')}</th>
											<th className="text-center">{t('EMPLOYEES')}%</th>
											<th className="text-center">{t('NET_PROFIT')}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>{formatMoney(data?.summaryPercent?.balanceAmount)}</td>
											<td className="text-center">{formatMoney(data?.summaryPercent?.salesAmount)}</td>
											<td className="text-center">{formatMoney(data?.salesAmount?.totalReturnedAmount)}</td>
											<td className="text-center">{formatMoney(data?.summaryPercent?.grossIncome)}</td>
											<td className="text-center">{formatMoney(data?.summaryPercent?.loyaltyBonus)}</td>
											<td className="text-center">{formatMoney(data?.summaryPercent?.expenseAmount)}</td>
											<td className="text-center">
												<input type="text" className="auto-width-input"
													value={employeeExpences}
													onChange={(e) => {
														if (Number(e.target.value) > 0) {
															setEmployeeExpences(e.target.value)
															setTotalEmployeeExpence(e.target.value)
															setPercentage(0)
														}
													}} />
											</td>
											<td className="text-center">
												<input type="number" className="auto-width-input"
													value={percentage}
													onChange={(e) => {
														if (Number(e.target.value) < 101) {
															setPercentage(e.target.value)
															setTotalEmployeeExpence(data?.summaryPercent?.grossIncome / 100 * e.target.value)
															setEmployeeExpences(0)
														}
														
													}} />
											</td>
											<td className="text-center">
												{
													formatMoney(
														data?.summaryPercent?.grossIncome -
														data?.summaryPercent?.loyaltyBonus -
														data?.summaryPercent?.expenseAmount -
														totalEmployeeExpence
													)
												}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12">
					<div className="card">
						<div className="card-body">
							<h5 className="mb-1 text-center">{t('CASHIERS')}</h5>
							<div className="table-responsive">
								<table className="table table-striped mb-0">
									<thead>
										<tr>
											<th>{t('NAME')}</th>
											<th className="text-center">{t('NUMBER_OF_CHECKS')}</th>
											<th className="text-center">{t('PRODUCT_QUANTITY')}</th>
											<th className="text-center">{t('SALE_AMOUNT')}</th>
											<th className="text-center">{t('DISCOUNT_AMOUNT')}</th>
											<th className="text-center">{t('NUMBER_OF_CHECKS')} ({t('RETURN')?.toLowerCase()})</th>
											<th className="text-center">{t('PRODUCT_QUANTITY')} ({t('RETURN')?.toLowerCase()})</th>
											<th className="text-center">{t('REFUND_AMOUNT')}</th>
											<th className="text-center">{t('GROSS_INCOME')}</th>
											<th className="text-center">{t('EXPENSES')}</th>
										</tr>
									</thead>
									<tbody>
										{data?.cashierAmount?.map((item, index) => (
											<tr key={index}>
												<td>{item.cashierName}</td>
												<td className="text-center">{item.countCheque}</td>
												<td className="text-center">{item.countProduct}</td>
												<td className="text-center">{formatMoney(item.totalAmount)}</td>
												<td className="text-center">{formatMoney(item.discountAmount)}</td>
												<td className="text-center">{item.countReturnedCheque}</td>
												<td className="text-center">{item.countReturnedProduct}</td>
												<td className="text-center">{formatMoney(item.totalReturnedAmount)}</td>
												<td className="text-center">{formatMoney(item.grossIncome)}</td>
												<td className="text-center">{formatMoney(item.expense)}</td>
											</tr>
										))
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index
