import React, { useEffect, useState } from "react"
import { useNavigate, useParams, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import { useForm, Controller } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from './validation'

import { GET, POST, PUT} from 'helpers/api'

function CreateUpdate() {
	const { id } = useParams();
	const { t } = useTranslation()
	const navigate = useNavigate();

	const reduxSettings = useSelector(state => state.settings)

	const { register, handleSubmit, formState, control, setValue, getValues } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
		defaultValues: {
			name_ru: '',
			name_uz_latn: '',
			name_uz_cyrl: '',
			parent_id: 2
		}
	});
	const { errors } = formState

	const [parents, setParents] = useState([])
	const [parentsInitial, setParentsInitial] = useState([])

	async function createUpdate(validatedData) {
		var sendData = { ...validatedData }
		var response;
		if (sendData.id) {
			response = await PUT('/services/web/api/product-category', sendData, { loader: true })
		} else {
			response = await POST('/services/web/api/product-category', sendData, { loader: true })
		}

		if (response) {
			navigate('/product-categories')
		}
	}


	async function getData() {
		const response = await GET('/services/web/api/product-category/' + id, {}, { loader: true })
		
		for (const [key, value] of Object.entries(response)) {
			console.log(key + "  " + value);
			setValue(key, value)
		}
	}

	async function getParents() {
		const response = await GET('/services/web/api/additional-product-categories?parentOnly=true')
		
		var arr = []
		arr.push(
			{
				label: "-",
				child: false
			}
		)
		for (let i = 0; i < response.length; i++) {
			response[i]['label'] = ' ' + response[i]['name']
			response[i].child = false;
			arr.push(response[i])
			
		}
		setParents(arr)
		setParentsInitial(response)
	}


	useEffect(() => {
		if (id) {
			getData()
			getParents()
		} else {
			getParents()
			if (reduxSettings.parent_id) {
				setValue('parent_id', reduxSettings.parent_id)
			}
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	

	

	return (
		<>
			<div className="page-header d-flex mb-2">
				<div className="d-flex">
					<h4 className="vertical-center">
						{t('ADDITIONAL_PRODUCT_CATEGORIES')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						{!id ?
							<h6>{t('CREATE')}</h6>
							:
							<h6>{t('EDIT')}</h6>
						}
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<form autoComplete="off">
						<div className="row">
							<div className="col-md-3 mt-3">
								<div className="form-group">
									<label>{t("NAME_OF_PRODUCT")} (русский) <span className="required-mark">*</span></label>
									<input type="text" className="form-control" tabIndex="1" name="name_ru" {...register('name_ru')} autoFocus />
									<span className="text-danger text-sm">{errors.name_ru?.message}</span>
								</div>
							</div>
							<div className="col-md-3 mt-3">
								<div className="form-group">
									<label>{t("NAME_OF_PRODUCT")} (o'zbekcha)<span className="required-mark">*</span></label>
									<input type="text" className="form-control" tabIndex="1" name="name_uz_latn" {...register('name_uz_latn')} autoFocus />
									<span className="text-danger text-sm">{errors.name_uz_latn?.message}</span>
								</div>
							</div>
							<div className="col-md-3 mt-3">
								<div className="form-group">
									<label>{t("NAME_OF_PRODUCT")} (ўзбекча)<span className="required-mark">*</span></label>
									<input type="text" className="form-control" tabIndex="1" name="name_uz_cyrl" {...register('name_uz_cyrl')} autoFocus />
									<span className="text-danger text-sm">{errors.name_uz_cyrl?.message}</span>
								</div>
							</div>
							
							<div className="col-md-3 mt-3">
								<div className="form-group">
									<label>{t('CATEGORY')}<span className="required-mark">*</span></label>
									<Controller
										name="parent_id"
										control={control}
										render={({ field }) =>
											<Select
												{...field}
												options={parents}
												value={parentsInitial.find(option => option.id === getValues('parent_id'))}
												onChange={(e) => setValue('parent_id', e.id)}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.label}
												getOptionValue={(option) => option.id}
											/>
										}
									/>
								</div>
							</div>
						</div>

						<div className="btn-wrapper">
							<Link className="btn btn-warning btn-rounded btn-wide me-2" to="/product-categories">
								{t('CANCEL')}
							</Link>
							<button type="button" className="btn btn-primary btn-rounded btn-wide btn-wide" tabIndex="4" onClick={handleSubmit(createUpdate)}>{t('SAVE')}</button>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default CreateUpdate
