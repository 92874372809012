import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import useDidMountEffect from 'pages/parts/useDidMountEffect'
import Pagination from 'pages/parts/Pagination'

import { PGET } from 'helpers/api'
import { returnSign } from "helpers/helpers"
import { SET_TABLE_FILTER } from 'store/tableFilter/actions';
import { DebounceInput } from "react-debounce-input"

function Index() {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const reduxSettings = useSelector(state => state.settings)
	const reduxTableFilter = useSelector(state => state.tableFilter)


	
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])
	const [filterData, setFilterData] = useState({
		'search': '',
	})

	async function searchWithFilter(settings = { tableSort: '' }) {

		var urlParams = ""

		if (filterData?.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData?.search
		if (filterData?.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData?.page


		const response = await PGET(`/services/web/api/additional-product-categories${urlParams}`, {}, { loader: true })
		
		
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
			setData(response.data)
		}
	}

	async function paginate(data) {
		setFilterData({ ...filterData, 'page': data.selected })
	}	

	useEffect(() => {

		if (reduxTableFilter.page === 'product-categories') {
			setFilterData(reduxTableFilter.filters)
		} else {
			searchWithFilter()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		dispatch(SET_TABLE_FILTER({
			'page': 'product-categories',
			'filters': {
				'search': filterData.search ? filterData.search : '',
				'page': filterData.page ? filterData.page : 0,
			}
		}))
	}, [filterData]) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		searchWithFilter()
	}, [filterData.page, filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('ADDITIONAL_PRODUCT_CATEGORIES')}
				</h4>
				<div className="d-flex">
					<Link className="btn-rounded btn btn-primary" to="/product-categories-create">
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</Link>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<div className="position-relative me-2">
								<i className="uil-search input-search-icon"></i>
								<DebounceInput
									type="text"
									className="form-control"
									placeholder={t('SEARCH') + '...'}
									minLength={3}
									debounceTimeout={1000}
									value={filterData.search}
									onChange={(e) => {
										if (e.target.value.length > 0) {
											setFilterData({ ...filterData, search: e.target.value })
										} else {
											setFilterData({ ...filterData, search: '' })
										}
									}}
									onKeyUp={(e) => {
										if (e.keyCode === 13) {
											if (e.target.value.length > 0) {
												setFilterData({ ...filterData, search: e.target.value })
											} else {
												setFilterData({ ...filterData, search: '' })
											}

										}
									}}
								/>
							</div>
						</div>
					</div>

					

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th className="text-center">{t('CATEGORY_NAME')}</th>
									<th className="text-center">{t('TYPE')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td>{item.name}</td>
										<td>{item.parentName}</td>
										<td>
											<div className="d-flex justify-content-center">
												<Link to={'/product-categories-update/' + item.id}>
													<div className="table-action-button table-action-primary-button">
														<i className="uil-edit-alt"></i>
													</div>
												</Link>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate} filters={reduxTableFilter?.filters}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
